/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InstitutionTierConfig } from './InstitutionTierConfig';
import {
    InstitutionTierConfigFromJSON,
    InstitutionTierConfigFromJSONTyped,
    InstitutionTierConfigToJSON,
} from './InstitutionTierConfig';
import type { OrganizationType } from './OrganizationType';
import {
    OrganizationTypeFromJSON,
    OrganizationTypeFromJSONTyped,
    OrganizationTypeToJSON,
} from './OrganizationType';

/**
 * Represents an institution which has been approved to validate researchers who wish to use the system, including the email patterns which we use to validate users.
 * @export
 * @interface Institution
 */
export interface Institution {
    /**
     * A short unique name for the Institution used as an identifier, such as 'VUMC'
     * @type {string}
     * @memberof Institution
     */
    shortName: string;
    /**
     * A more descriptive name for the Institution, such as 'Vanderbilt University Medical Center'
     * @type {string}
     * @memberof Institution
     */
    displayName: string;
    /**
     * 
     * @type {OrganizationType}
     * @memberof Institution
     */
    organizationTypeEnum: OrganizationType;
    /**
     * The detailed Organization Type of this institution, as text, if its enumerated type is OTHER
     * @type {string}
     * @memberof Institution
     */
    organizationTypeOtherText?: string;
    /**
     * Optional url for institution's handling their own access requests
     * @type {string}
     * @memberof Institution
     */
    requestAccessUrl?: string;
    /**
     * Institution-specific instructions for users to be sent in email
     * @type {string}
     * @memberof Institution
     */
    userInstructions?: string;
    /**
     * List of requirements on how members can get access for each tier.
     * @type {Array<InstitutionTierConfig>}
     * @memberof Institution
     */
    tierConfigs?: Array<InstitutionTierConfig>;
}

/**
 * Check if a given object implements the Institution interface.
 */
export function instanceOfInstitution(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shortName" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "organizationTypeEnum" in value;

    return isInstance;
}

export function InstitutionFromJSON(json: any): Institution {
    return InstitutionFromJSONTyped(json, false);
}

export function InstitutionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Institution {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shortName': json['shortName'],
        'displayName': json['displayName'],
        'organizationTypeEnum': OrganizationTypeFromJSON(json['organizationTypeEnum']),
        'organizationTypeOtherText': !exists(json, 'organizationTypeOtherText') ? undefined : json['organizationTypeOtherText'],
        'requestAccessUrl': !exists(json, 'requestAccessUrl') ? undefined : json['requestAccessUrl'],
        'userInstructions': !exists(json, 'userInstructions') ? undefined : json['userInstructions'],
        'tierConfigs': !exists(json, 'tierConfigs') ? undefined : ((json['tierConfigs'] as Array<any>).map(InstitutionTierConfigFromJSON)),
    };
}

export function InstitutionToJSON(value?: Institution | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shortName': value.shortName,
        'displayName': value.displayName,
        'organizationTypeEnum': OrganizationTypeToJSON(value.organizationTypeEnum),
        'organizationTypeOtherText': value.organizationTypeOtherText,
        'requestAccessUrl': value.requestAccessUrl,
        'userInstructions': value.userInstructions,
        'tierConfigs': value.tierConfigs === undefined ? undefined : ((value.tierConfigs as Array<any>).map(InstitutionTierConfigToJSON)),
    };
}

