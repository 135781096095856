/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FeaturedWorkspaceCategory } from './FeaturedWorkspaceCategory';
import {
    FeaturedWorkspaceCategoryFromJSON,
    FeaturedWorkspaceCategoryFromJSONTyped,
    FeaturedWorkspaceCategoryToJSON,
} from './FeaturedWorkspaceCategory';

/**
 * 
 * @export
 * @interface FeaturedWorkspace
 */
export interface FeaturedWorkspace {
    /**
     * the name of the workspace
     * @type {string}
     * @memberof FeaturedWorkspace
     */
    name?: string;
    /**
     * the namespace of the workspace
     * @type {string}
     * @memberof FeaturedWorkspace
     */
    namespace?: string;
    /**
     * the Firecloud id of the workspace
     * @type {string}
     * @memberof FeaturedWorkspace
     */
    id?: string;
    /**
     * 
     * @type {FeaturedWorkspaceCategory}
     * @memberof FeaturedWorkspace
     */
    category?: FeaturedWorkspaceCategory;
}

/**
 * Check if a given object implements the FeaturedWorkspace interface.
 */
export function instanceOfFeaturedWorkspace(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FeaturedWorkspaceFromJSON(json: any): FeaturedWorkspace {
    return FeaturedWorkspaceFromJSONTyped(json, false);
}

export function FeaturedWorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeaturedWorkspace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'category': !exists(json, 'category') ? undefined : FeaturedWorkspaceCategoryFromJSON(json['category']),
    };
}

export function FeaturedWorkspaceToJSON(value?: FeaturedWorkspace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'namespace': value.namespace,
        'id': value.id,
        'category': FeaturedWorkspaceCategoryToJSON(value.category),
    };
}

