/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Single criteria for a cohort or concept set definition
 * @export
 * @interface Criteria
 */
export interface Criteria {
    /**
     * ID of the criteria, immutable
     * @type {string}
     * @memberof Criteria
     */
    id: string;
    /**
     * Name of the criteria
     * @type {string}
     * @memberof Criteria
     */
    displayName: string;
    /**
     * Name of the plugin that generated this criteria
     * @type {string}
     * @memberof Criteria
     */
    pluginName: string;
    /**
     * Version of the plugin that generated this criteria
     * @type {number}
     * @memberof Criteria
     */
    pluginVersion?: number;
    /**
     * Id of the predefined criteria in the config
     * @type {string}
     * @memberof Criteria
     */
    predefinedId?: string;
    /**
     * Name of the selector or modifier used by this config
     * @type {string}
     * @memberof Criteria
     */
    selectorOrModifierName?: string;
    /**
     * Serialized plugin-specific representation of the user's selection
     * @type {string}
     * @memberof Criteria
     */
    selectionData: string;
    /**
     * Serialized plugin-specific UI configuration for the criteria
     * @type {string}
     * @memberof Criteria
     */
    uiConfig: string;
    /**
     * Unstructured key-value tags for the criteria
     * @type {{ [key: string]: string; }}
     * @memberof Criteria
     */
    tags: { [key: string]: string; };
}

/**
 * Check if a given object implements the Criteria interface.
 */
export function instanceOfCriteria(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "pluginName" in value;
    isInstance = isInstance && "selectionData" in value;
    isInstance = isInstance && "uiConfig" in value;
    isInstance = isInstance && "tags" in value;

    return isInstance;
}

export function CriteriaFromJSON(json: any): Criteria {
    return CriteriaFromJSONTyped(json, false);
}

export function CriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Criteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'pluginName': json['pluginName'],
        'pluginVersion': !exists(json, 'pluginVersion') ? undefined : json['pluginVersion'],
        'predefinedId': !exists(json, 'predefinedId') ? undefined : json['predefinedId'],
        'selectorOrModifierName': !exists(json, 'selectorOrModifierName') ? undefined : json['selectorOrModifierName'],
        'selectionData': json['selectionData'],
        'uiConfig': json['uiConfig'],
        'tags': json['tags'],
    };
}

export function CriteriaToJSON(value?: Criteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'pluginName': value.pluginName,
        'pluginVersion': value.pluginVersion,
        'predefinedId': value.predefinedId,
        'selectorOrModifierName': value.selectorOrModifierName,
        'selectionData': value.selectionData,
        'uiConfig': value.uiConfig,
        'tags': value.tags,
    };
}

