/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Criteria } from './Criteria';
import {
    CriteriaFromJSON,
    CriteriaFromJSONTyped,
    CriteriaToJSON,
} from './Criteria';
import type { EntityOutput } from './EntityOutput';
import {
    EntityOutputFromJSON,
    EntityOutputFromJSONTyped,
    EntityOutputToJSON,
} from './EntityOutput';

/**
 * 
 * @export
 * @interface ConceptSetUpdateInfo
 */
export interface ConceptSetUpdateInfo {
    /**
     * Human readable name of the concept set
     * @type {string}
     * @memberof ConceptSetUpdateInfo
     */
    displayName?: string;
    /**
     * Description of the concept set
     * @type {string}
     * @memberof ConceptSetUpdateInfo
     */
    description?: string;
    /**
     * Criteria that define the entity filter
     * @type {Array<Criteria>}
     * @memberof ConceptSetUpdateInfo
     */
    criteria?: Array<Criteria>;
    /**
     * Outputs per entity
     * @type {Array<EntityOutput>}
     * @memberof ConceptSetUpdateInfo
     */
    entityOutputs?: Array<EntityOutput>;
}

/**
 * Check if a given object implements the ConceptSetUpdateInfo interface.
 */
export function instanceOfConceptSetUpdateInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConceptSetUpdateInfoFromJSON(json: any): ConceptSetUpdateInfo {
    return ConceptSetUpdateInfoFromJSONTyped(json, false);
}

export function ConceptSetUpdateInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConceptSetUpdateInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'criteria': !exists(json, 'criteria') ? undefined : ((json['criteria'] as Array<any>).map(CriteriaFromJSON)),
        'entityOutputs': !exists(json, 'entityOutputs') ? undefined : ((json['entityOutputs'] as Array<any>).map(EntityOutputFromJSON)),
    };
}

export function ConceptSetUpdateInfoToJSON(value?: ConceptSetUpdateInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'description': value.description,
        'criteria': value.criteria === undefined ? undefined : ((value.criteria as Array<any>).map(CriteriaToJSON)),
        'entityOutputs': value.entityOutputs === undefined ? undefined : ((value.entityOutputs as Array<any>).map(EntityOutputToJSON)),
    };
}

