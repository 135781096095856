/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Criteria } from './Criteria';
import {
    CriteriaFromJSON,
    CriteriaFromJSONTyped,
    CriteriaToJSON,
} from './Criteria';

/**
 * Group of related criteria (primary + modifiers)
 * @export
 * @interface CriteriaGroup
 */
export interface CriteriaGroup {
    /**
     * ID of the group, immutable
     * @type {string}
     * @memberof CriteriaGroup
     */
    id: string;
    /**
     * Name of the group
     * @type {string}
     * @memberof CriteriaGroup
     */
    displayName: string;
    /**
     * Set of criteria in the group
     * @type {Array<Criteria>}
     * @memberof CriteriaGroup
     */
    criteria: Array<Criteria>;
}

/**
 * Check if a given object implements the CriteriaGroup interface.
 */
export function instanceOfCriteriaGroup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "criteria" in value;

    return isInstance;
}

export function CriteriaGroupFromJSON(json: any): CriteriaGroup {
    return CriteriaGroupFromJSONTyped(json, false);
}

export function CriteriaGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): CriteriaGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'criteria': ((json['criteria'] as Array<any>).map(CriteriaFromJSON)),
    };
}

export function CriteriaGroupToJSON(value?: CriteriaGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'criteria': ((value.criteria as Array<any>).map(CriteriaToJSON)),
    };
}

