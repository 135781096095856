/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GpuConfig } from './GpuConfig';
import {
    GpuConfigFromJSON,
    GpuConfigFromJSONTyped,
    GpuConfigToJSON,
} from './GpuConfig';
import type { PersistentDiskRequest } from './PersistentDiskRequest';
import {
    PersistentDiskRequestFromJSON,
    PersistentDiskRequestFromJSONTyped,
    PersistentDiskRequestToJSON,
} from './PersistentDiskRequest';

/**
 * Configuration for Google Compute Engine instances with Persistent Disk.
 * @export
 * @interface GceWithPdConfig
 */
export interface GceWithPdConfig {
    /**
     * 
     * @type {PersistentDiskRequest}
     * @memberof GceWithPdConfig
     */
    persistentDisk?: PersistentDiskRequest;
    /**
     * Optional, the machine type determines the number of CPUs and memory for the master node. For example "n1-standard-16"
     * or "n1-highmem-64". If unspecified, defaults to creating a "n1-standard-4" machine. To decide which is right for you,
     * see https://cloud.google.com/compute/docs/machine-types
     * @type {string}
     * @memberof GceWithPdConfig
     */
    machineType?: string;
    /**
     * 
     * @type {GpuConfig}
     * @memberof GceWithPdConfig
     */
    gpuConfig?: GpuConfig;
}

/**
 * Check if a given object implements the GceWithPdConfig interface.
 */
export function instanceOfGceWithPdConfig(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GceWithPdConfigFromJSON(json: any): GceWithPdConfig {
    return GceWithPdConfigFromJSONTyped(json, false);
}

export function GceWithPdConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): GceWithPdConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'persistentDisk': !exists(json, 'persistentDisk') ? undefined : PersistentDiskRequestFromJSON(json['persistentDisk']),
        'machineType': !exists(json, 'machineType') ? undefined : json['machineType'],
        'gpuConfig': !exists(json, 'gpuConfig') ? undefined : GpuConfigFromJSON(json['gpuConfig']),
    };
}

export function GceWithPdConfigToJSON(value?: GceWithPdConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'persistentDisk': PersistentDiskRequestToJSON(value.persistentDisk),
        'machineType': value.machineType,
        'gpuConfig': GpuConfigToJSON(value.gpuConfig),
    };
}

