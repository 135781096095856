/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserTierEligibility
 */
export interface UserTierEligibility {
    /**
     * Short name of access tier.
     * @type {string}
     * @memberof UserTierEligibility
     */
    accessTierShortName?: string;
    /**
     * User eligibility for tiers is based on their contact emails and their institution's tier
     * requirements. This does not mean the user has access to these tiers; to gain
     * access, the user must still complete all the necessary access requirement steps for each tier.
     * @type {boolean}
     * @memberof UserTierEligibility
     */
    eligible?: boolean;
    /**
     * If eRA Commons linking is required for this access tier.
     * @type {boolean}
     * @memberof UserTierEligibility
     */
    eraRequired?: boolean;
}

/**
 * Check if a given object implements the UserTierEligibility interface.
 */
export function instanceOfUserTierEligibility(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserTierEligibilityFromJSON(json: any): UserTierEligibility {
    return UserTierEligibilityFromJSONTyped(json, false);
}

export function UserTierEligibilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserTierEligibility {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessTierShortName': !exists(json, 'accessTierShortName') ? undefined : json['accessTierShortName'],
        'eligible': !exists(json, 'eligible') ? undefined : json['eligible'],
        'eraRequired': !exists(json, 'eraRequired') ? undefined : json['eraRequired'],
    };
}

export function UserTierEligibilityToJSON(value?: UserTierEligibility | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessTierShortName': value.accessTierShortName,
        'eligible': value.eligible,
        'eraRequired': value.eraRequired,
    };
}

