/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InstitutionMembershipRequirement } from './InstitutionMembershipRequirement';
import {
    InstitutionMembershipRequirementFromJSON,
    InstitutionMembershipRequirementFromJSONTyped,
    InstitutionMembershipRequirementToJSON,
} from './InstitutionMembershipRequirement';
import type { OrganizationType } from './OrganizationType';
import {
    OrganizationTypeFromJSON,
    OrganizationTypeFromJSONTyped,
    OrganizationTypeToJSON,
} from './OrganizationType';

/**
 * Represents an institution which has been approved to validate researchers who wish to use the system. Does not include the sensitive email patterns used to validate users.
 * @export
 * @interface PublicInstitutionDetails
 */
export interface PublicInstitutionDetails {
    /**
     * A short unique name for the Institution used as an identifier, such as 'VUMC'
     * @type {string}
     * @memberof PublicInstitutionDetails
     */
    shortName: string;
    /**
     * A more descriptive name for the Institution, such as 'Vanderbilt University Medical Center'
     * @type {string}
     * @memberof PublicInstitutionDetails
     */
    displayName: string;
    /**
     * 
     * @type {OrganizationType}
     * @memberof PublicInstitutionDetails
     */
    organizationTypeEnum: OrganizationType;
    /**
     * 
     * @type {InstitutionMembershipRequirement}
     * @memberof PublicInstitutionDetails
     */
    registeredTierMembershipRequirement?: InstitutionMembershipRequirement;
    /**
     * Optional url for institution's handling their own access requests
     * @type {string}
     * @memberof PublicInstitutionDetails
     */
    requestAccessUrl?: string;
}

/**
 * Check if a given object implements the PublicInstitutionDetails interface.
 */
export function instanceOfPublicInstitutionDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shortName" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "organizationTypeEnum" in value;

    return isInstance;
}

export function PublicInstitutionDetailsFromJSON(json: any): PublicInstitutionDetails {
    return PublicInstitutionDetailsFromJSONTyped(json, false);
}

export function PublicInstitutionDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicInstitutionDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shortName': json['shortName'],
        'displayName': json['displayName'],
        'organizationTypeEnum': OrganizationTypeFromJSON(json['organizationTypeEnum']),
        'registeredTierMembershipRequirement': !exists(json, 'registeredTierMembershipRequirement') ? undefined : InstitutionMembershipRequirementFromJSON(json['registeredTierMembershipRequirement']),
        'requestAccessUrl': !exists(json, 'requestAccessUrl') ? undefined : json['requestAccessUrl'],
    };
}

export function PublicInstitutionDetailsToJSON(value?: PublicInstitutionDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shortName': value.shortName,
        'displayName': value.displayName,
        'organizationTypeEnum': OrganizationTypeToJSON(value.organizationTypeEnum),
        'registeredTierMembershipRequirement': InstitutionMembershipRequirementToJSON(value.registeredTierMembershipRequirement),
        'requestAccessUrl': value.requestAccessUrl,
    };
}

