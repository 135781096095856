/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FeaturedWorkspace } from './FeaturedWorkspace';
import {
    FeaturedWorkspaceFromJSON,
    FeaturedWorkspaceFromJSONTyped,
    FeaturedWorkspaceToJSON,
} from './FeaturedWorkspace';

/**
 * 
 * @export
 * @interface FeaturedWorkspacesConfigResponse
 */
export interface FeaturedWorkspacesConfigResponse {
    /**
     * 
     * @type {Array<FeaturedWorkspace>}
     * @memberof FeaturedWorkspacesConfigResponse
     */
    featuredWorkspacesList?: Array<FeaturedWorkspace>;
}

/**
 * Check if a given object implements the FeaturedWorkspacesConfigResponse interface.
 */
export function instanceOfFeaturedWorkspacesConfigResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FeaturedWorkspacesConfigResponseFromJSON(json: any): FeaturedWorkspacesConfigResponse {
    return FeaturedWorkspacesConfigResponseFromJSONTyped(json, false);
}

export function FeaturedWorkspacesConfigResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeaturedWorkspacesConfigResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'featuredWorkspacesList': !exists(json, 'featuredWorkspacesList') ? undefined : ((json['featuredWorkspacesList'] as Array<any>).map(FeaturedWorkspaceFromJSON)),
    };
}

export function FeaturedWorkspacesConfigResponseToJSON(value?: FeaturedWorkspacesConfigResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'featuredWorkspacesList': value.featuredWorkspacesList === undefined ? undefined : ((value.featuredWorkspacesList as Array<any>).map(FeaturedWorkspaceToJSON)),
    };
}

