/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * actions a user can have authority/permission to perform
 * @export
 */
export const Authority = {
    DEVELOPER: 'DEVELOPER',
    ACCESS_CONTROL_ADMIN: 'ACCESS_CONTROL_ADMIN',
    FEATURED_WORKSPACE_ADMIN: 'FEATURED_WORKSPACE_ADMIN',
    COMMUNICATIONS_ADMIN: 'COMMUNICATIONS_ADMIN',
    SECURITY_ADMIN: 'SECURITY_ADMIN',
    INSTITUTION_ADMIN: 'INSTITUTION_ADMIN',
    RESEARCHER_DATA_VIEW: 'RESEARCHER_DATA_VIEW'
} as const;
export type Authority = typeof Authority[keyof typeof Authority];


export function AuthorityFromJSON(json: any): Authority {
    return AuthorityFromJSONTyped(json, false);
}

export function AuthorityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Authority {
    return json as Authority;
}

export function AuthorityToJSON(value?: Authority | null): any {
    return value as any;
}

