/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Disk,
  EmptyResponse,
  ErrorResponse,
  ListDisksResponse,
} from '../models';
import {
    DiskFromJSON,
    DiskToJSON,
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ListDisksResponseFromJSON,
    ListDisksResponseToJSON,
} from '../models';

export interface DisksApiDeleteDiskRequest {
    workspaceNamespace: string;
    diskName: string;
}

export interface DisksApiGetDiskRequest {
    workspaceNamespace: string;
    diskName: string;
}

export interface DisksApiListOwnedDisksInWorkspaceRequest {
    workspaceNamespace: string;
}

export interface DisksApiUpdateDiskRequest {
    workspaceNamespace: string;
    diskName: string;
    diskSize: number;
}

/**
 * 
 */
export class DisksApi extends runtime.BaseAPI {

    /**
     * Delete a persistent disk by name.
     * Delete a persistent disk.
     */
    async deleteDiskRaw(requestParameters: DisksApiDeleteDiskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling deleteDisk.');
        }

        if (requestParameters.diskName === null || requestParameters.diskName === undefined) {
            throw new runtime.RequiredError('diskName','Required parameter requestParameters.diskName was null or undefined when calling deleteDisk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/disks/{diskName}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"diskName"}}`, encodeURIComponent(String(requestParameters.diskName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Delete a persistent disk by name.
     * Delete a persistent disk.
     */
    async deleteDisk(workspaceNamespace: string, diskName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.deleteDiskRaw({ workspaceNamespace: workspaceNamespace, diskName: diskName }, initOverrides);
        return await response.value();
    }

    /**
     * Get the user\'s workspace persistent disk by name.
     */
    async getDiskRaw(requestParameters: DisksApiGetDiskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Disk>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getDisk.');
        }

        if (requestParameters.diskName === null || requestParameters.diskName === undefined) {
            throw new runtime.RequiredError('diskName','Required parameter requestParameters.diskName was null or undefined when calling getDisk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/disks/{diskName}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"diskName"}}`, encodeURIComponent(String(requestParameters.diskName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiskFromJSON(jsonValue));
    }

    /**
     * Get the user\'s workspace persistent disk by name.
     */
    async getDisk(workspaceNamespace: string, diskName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Disk> {
        const response = await this.getDiskRaw({ workspaceNamespace: workspaceNamespace, diskName: diskName }, initOverrides);
        return await response.value();
    }

    /**
     * Lists persistent disks in the user\'s workspace. Only disks in READY/CREATING/RESTORING state will be returned.
     */
    async listOwnedDisksInWorkspaceRaw(requestParameters: DisksApiListOwnedDisksInWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDisksResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling listOwnedDisksInWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/disks`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDisksResponseFromJSON(jsonValue));
    }

    /**
     * Lists persistent disks in the user\'s workspace. Only disks in READY/CREATING/RESTORING state will be returned.
     */
    async listOwnedDisksInWorkspace(workspaceNamespace: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDisksResponse> {
        const response = await this.listOwnedDisksInWorkspaceRaw({ workspaceNamespace: workspaceNamespace }, initOverrides);
        return await response.value();
    }

    /**
     * In order to update the configuration of a persistent disk, it must first be ready
     * Updates the configuration of a persistent disk
     */
    async updateDiskRaw(requestParameters: DisksApiUpdateDiskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateDisk.');
        }

        if (requestParameters.diskName === null || requestParameters.diskName === undefined) {
            throw new runtime.RequiredError('diskName','Required parameter requestParameters.diskName was null or undefined when calling updateDisk.');
        }

        if (requestParameters.diskSize === null || requestParameters.diskSize === undefined) {
            throw new runtime.RequiredError('diskSize','Required parameter requestParameters.diskSize was null or undefined when calling updateDisk.');
        }

        const queryParameters: any = {};

        if (requestParameters.diskSize !== undefined) {
            queryParameters['diskSize'] = requestParameters.diskSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/disks/{diskName}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"diskName"}}`, encodeURIComponent(String(requestParameters.diskName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * In order to update the configuration of a persistent disk, it must first be ready
     * Updates the configuration of a persistent disk
     */
    async updateDisk(workspaceNamespace: string, diskName: string, diskSize: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.updateDiskRaw({ workspaceNamespace: workspaceNamespace, diskName: diskName, diskSize: diskSize }, initOverrides);
        return await response.value();
    }

}
