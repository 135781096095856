/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RuntimeStatus } from './RuntimeStatus';
import {
    RuntimeStatusFromJSON,
    RuntimeStatusFromJSONTyped,
    RuntimeStatusToJSON,
} from './RuntimeStatus';

/**
 * This is a subset of options in a full runtimes response, copied from leonardo.yaml.
 * @export
 * @interface ListRuntimeResponse
 */
export interface ListRuntimeResponse {
    /**
     * Internal Leonardo ID of the runtime
     * @type {number}
     * @memberof ListRuntimeResponse
     */
    id: number;
    /**
     * The user-supplied name for the runtime
     * @type {string}
     * @memberof ListRuntimeResponse
     */
    runtimeName: string;
    /**
     * The Google Project used to create the runtime
     * @type {string}
     * @memberof ListRuntimeResponse
     */
    googleProject: string;
    /**
     * 
     * @type {RuntimeStatus}
     * @memberof ListRuntimeResponse
     */
    status: RuntimeStatus;
    /**
     * The date and time the runtime was created, in ISO-8601 format
     * @type {string}
     * @memberof ListRuntimeResponse
     */
    createdDate: string;
    /**
     * The labels to be placed on the runtime. Of type Map[String,String]
     * @type {object}
     * @memberof ListRuntimeResponse
     */
    labels: object;
    /**
     * The date and time the runtime was last accessed, in ISO-8601 format.
     * Date accessed is defined as the last time the runtime was created, modified, or accessed via the proxy.
     * @type {string}
     * @memberof ListRuntimeResponse
     */
    dateAccessed: string;
    /**
     * Whether there is a patch in progress on the runtime. Is used to indicate updates that require status transitions.
     * @type {boolean}
     * @memberof ListRuntimeResponse
     */
    patchInProgress?: boolean;
}

/**
 * Check if a given object implements the ListRuntimeResponse interface.
 */
export function instanceOfListRuntimeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "runtimeName" in value;
    isInstance = isInstance && "googleProject" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "labels" in value;
    isInstance = isInstance && "dateAccessed" in value;

    return isInstance;
}

export function ListRuntimeResponseFromJSON(json: any): ListRuntimeResponse {
    return ListRuntimeResponseFromJSONTyped(json, false);
}

export function ListRuntimeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListRuntimeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'runtimeName': json['runtimeName'],
        'googleProject': json['googleProject'],
        'status': RuntimeStatusFromJSON(json['status']),
        'createdDate': json['createdDate'],
        'labels': json['labels'],
        'dateAccessed': json['dateAccessed'],
        'patchInProgress': !exists(json, 'patchInProgress') ? undefined : json['patchInProgress'],
    };
}

export function ListRuntimeResponseToJSON(value?: ListRuntimeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'runtimeName': value.runtimeName,
        'googleProject': value.googleProject,
        'status': RuntimeStatusToJSON(value.status),
        'createdDate': value.createdDate,
        'labels': value.labels,
        'dateAccessed': value.dateAccessed,
        'patchInProgress': value.patchInProgress,
    };
}

