/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopyRequest
 */
export interface CopyRequest {
    /**
     * 
     * @type {string}
     * @memberof CopyRequest
     */
    toWorkspaceName: string;
    /**
     * 
     * @type {string}
     * @memberof CopyRequest
     */
    toWorkspaceNamespace: string;
    /**
     * 
     * @type {string}
     * @memberof CopyRequest
     */
    newName: string;
}

/**
 * Check if a given object implements the CopyRequest interface.
 */
export function instanceOfCopyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "toWorkspaceName" in value;
    isInstance = isInstance && "toWorkspaceNamespace" in value;
    isInstance = isInstance && "newName" in value;

    return isInstance;
}

export function CopyRequestFromJSON(json: any): CopyRequest {
    return CopyRequestFromJSONTyped(json, false);
}

export function CopyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'toWorkspaceName': json['toWorkspaceName'],
        'toWorkspaceNamespace': json['toWorkspaceNamespace'],
        'newName': json['newName'],
    };
}

export function CopyRequestToJSON(value?: CopyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'toWorkspaceName': value.toWorkspaceName,
        'toWorkspaceNamespace': value.toWorkspaceNamespace,
        'newName': value.newName,
    };
}

