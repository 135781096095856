/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConceptSet,
  ConceptSetCreateInfo,
  ConceptSetUpdateInfo,
  ErrorReport,
} from '../models';
import {
    ConceptSetFromJSON,
    ConceptSetToJSON,
    ConceptSetCreateInfoFromJSON,
    ConceptSetCreateInfoToJSON,
    ConceptSetUpdateInfoFromJSON,
    ConceptSetUpdateInfoToJSON,
    ErrorReportFromJSON,
    ErrorReportToJSON,
} from '../models';

export interface CreateConceptSetRequest {
    studyId: string;
    conceptSetCreateInfo: ConceptSetCreateInfo;
}

export interface DeleteConceptSetRequest {
    studyId: string;
    conceptSetId: string;
}

export interface GetConceptSetRequest {
    studyId: string;
    conceptSetId: string;
}

export interface ListConceptSetsRequest {
    studyId: string;
    offset?: number;
    limit?: number;
}

export interface UpdateConceptSetRequest {
    studyId: string;
    conceptSetId: string;
    conceptSetUpdateInfo: ConceptSetUpdateInfo;
}

/**
 * 
 */
export class ConceptSetsApi extends runtime.BaseAPI {

    /**
     * Create a new concept set
     */
    async createConceptSetRaw(requestParameters: CreateConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptSet>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling createConceptSet.');
        }

        if (requestParameters.conceptSetCreateInfo === null || requestParameters.conceptSetCreateInfo === undefined) {
            throw new runtime.RequiredError('conceptSetCreateInfo','Required parameter requestParameters.conceptSetCreateInfo was null or undefined when calling createConceptSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/conceptSets`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConceptSetCreateInfoToJSON(requestParameters.conceptSetCreateInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptSetFromJSON(jsonValue));
    }

    /**
     * Create a new concept set
     */
    async createConceptSet(requestParameters: CreateConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptSet> {
        const response = await this.createConceptSetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a concept set
     */
    async deleteConceptSetRaw(requestParameters: DeleteConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling deleteConceptSet.');
        }

        if (requestParameters.conceptSetId === null || requestParameters.conceptSetId === undefined) {
            throw new runtime.RequiredError('conceptSetId','Required parameter requestParameters.conceptSetId was null or undefined when calling deleteConceptSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/conceptSets/{conceptSetId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"conceptSetId"}}`, encodeURIComponent(String(requestParameters.conceptSetId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a concept set
     */
    async deleteConceptSet(requestParameters: DeleteConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteConceptSetRaw(requestParameters, initOverrides);
    }

    /**
     * Get an existing concept set
     */
    async getConceptSetRaw(requestParameters: GetConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptSet>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling getConceptSet.');
        }

        if (requestParameters.conceptSetId === null || requestParameters.conceptSetId === undefined) {
            throw new runtime.RequiredError('conceptSetId','Required parameter requestParameters.conceptSetId was null or undefined when calling getConceptSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/conceptSets/{conceptSetId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"conceptSetId"}}`, encodeURIComponent(String(requestParameters.conceptSetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptSetFromJSON(jsonValue));
    }

    /**
     * Get an existing concept set
     */
    async getConceptSet(requestParameters: GetConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptSet> {
        const response = await this.getConceptSetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all concept sets in a study
     */
    async listConceptSetsRaw(requestParameters: ListConceptSetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConceptSet>>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling listConceptSets.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/conceptSets`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConceptSetFromJSON));
    }

    /**
     * List all concept sets in a study
     */
    async listConceptSets(requestParameters: ListConceptSetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConceptSet>> {
        const response = await this.listConceptSetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing concept set
     */
    async updateConceptSetRaw(requestParameters: UpdateConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptSet>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling updateConceptSet.');
        }

        if (requestParameters.conceptSetId === null || requestParameters.conceptSetId === undefined) {
            throw new runtime.RequiredError('conceptSetId','Required parameter requestParameters.conceptSetId was null or undefined when calling updateConceptSet.');
        }

        if (requestParameters.conceptSetUpdateInfo === null || requestParameters.conceptSetUpdateInfo === undefined) {
            throw new runtime.RequiredError('conceptSetUpdateInfo','Required parameter requestParameters.conceptSetUpdateInfo was null or undefined when calling updateConceptSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/conceptSets/{conceptSetId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"conceptSetId"}}`, encodeURIComponent(String(requestParameters.conceptSetId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ConceptSetUpdateInfoToJSON(requestParameters.conceptSetUpdateInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptSetFromJSON(jsonValue));
    }

    /**
     * Update an existing concept set
     */
    async updateConceptSet(requestParameters: UpdateConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptSet> {
        const response = await this.updateConceptSetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
