/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EmptyResponse,
  ErrorResponse,
  WorkspaceResponseListResponse,
} from '../models';
import {
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    WorkspaceResponseListResponseFromJSON,
    WorkspaceResponseListResponseToJSON,
} from '../models';

export interface FeaturedWorkspaceApiGetFeaturedWorkspacesByCategoryRequest {
    category: string;
}

/**
 * 
 */
export class FeaturedWorkspaceApi extends runtime.BaseAPI {

    /**
     * Saves featured workspace from config to database. This is TEMP and will be removed once we run it
     */
    async backFillFeaturedWorkspacesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/backfillfeatured`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Saves featured workspace from config to database. This is TEMP and will be removed once we run it
     */
    async backFillFeaturedWorkspaces(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.backFillFeaturedWorkspacesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of featured workspaces
     */
    async getFeaturedWorkspacesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceResponseListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/featured`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceResponseListResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of featured workspaces
     */
    async getFeaturedWorkspaces(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceResponseListResponse> {
        const response = await this.getFeaturedWorkspacesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetches a list of published workspaces filtered by a specified category
     */
    async getFeaturedWorkspacesByCategoryRaw(requestParameters: FeaturedWorkspaceApiGetFeaturedWorkspacesByCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceResponseListResponse>> {
        if (requestParameters.category === null || requestParameters.category === undefined) {
            throw new runtime.RequiredError('category','Required parameter requestParameters.category was null or undefined when calling getFeaturedWorkspacesByCategory.');
        }

        const queryParameters: any = {};

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/featuredWorkspacesByCategory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceResponseListResponseFromJSON(jsonValue));
    }

    /**
     * Fetches a list of published workspaces filtered by a specified category
     */
    async getFeaturedWorkspacesByCategory(category: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceResponseListResponse> {
        const response = await this.getFeaturedWorkspacesByCategoryRaw({ category: category }, initOverrides);
        return await response.value();
    }

}
