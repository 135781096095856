/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppType } from './AppType';
import {
    AppTypeFromJSON,
    AppTypeFromJSONTyped,
    AppTypeToJSON,
} from './AppType';
import type { DiskStatus } from './DiskStatus';
import {
    DiskStatusFromJSON,
    DiskStatusFromJSONTyped,
    DiskStatusToJSON,
} from './DiskStatus';
import type { DiskType } from './DiskType';
import {
    DiskTypeFromJSON,
    DiskTypeFromJSONTyped,
    DiskTypeToJSON,
} from './DiskType';

/**
 * The configuration of a persistent disk, returned in disk responses
 * @export
 * @interface Disk
 */
export interface Disk {
    /**
     * Name of the persistent disk
     * @type {string}
     * @memberof Disk
     */
    name: string;
    /**
     * 
     * @type {DiskStatus}
     * @memberof Disk
     */
    status?: DiskStatus;
    /**
     * Size of persistent disk in GB
     * @type {number}
     * @memberof Disk
     */
    size: number;
    /**
     * 
     * @type {DiskType}
     * @memberof Disk
     */
    diskType: DiskType;
    /**
     * Block size of persistent disk in bytes
     * @type {number}
     * @memberof Disk
     */
    blockSize: number;
    /**
     * 
     * @type {AppType}
     * @memberof Disk
     */
    appType?: AppType;
    /**
     * True if disk is used by Runtime or being used by GCE runtime
     * @type {boolean}
     * @memberof Disk
     */
    gceRuntime?: boolean;
    /**
     * The email for the user that created this runtime
     * @type {string}
     * @memberof Disk
     */
    creator?: string;
    /**
     * timestamp for the date this runtime was created in ISO 8601 format
     * @type {string}
     * @memberof Disk
     */
    createdDate?: string;
    /**
     * timestamp for the date this runtime was last accessed in ISO 8601 format. This is null if it has not been deleted yet.
     * @type {string}
     * @memberof Disk
     */
    dateAccessed?: string;
}

/**
 * Check if a given object implements the Disk interface.
 */
export function instanceOfDisk(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "diskType" in value;
    isInstance = isInstance && "blockSize" in value;

    return isInstance;
}

export function DiskFromJSON(json: any): Disk {
    return DiskFromJSONTyped(json, false);
}

export function DiskFromJSONTyped(json: any, ignoreDiscriminator: boolean): Disk {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'status': !exists(json, 'status') ? undefined : DiskStatusFromJSON(json['status']),
        'size': json['size'],
        'diskType': DiskTypeFromJSON(json['diskType']),
        'blockSize': json['blockSize'],
        'appType': !exists(json, 'appType') ? undefined : AppTypeFromJSON(json['appType']),
        'gceRuntime': !exists(json, 'gceRuntime') ? undefined : json['gceRuntime'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'createdDate': !exists(json, 'createdDate') ? undefined : json['createdDate'],
        'dateAccessed': !exists(json, 'dateAccessed') ? undefined : json['dateAccessed'],
    };
}

export function DiskToJSON(value?: Disk | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'status': DiskStatusToJSON(value.status),
        'size': value.size,
        'diskType': DiskTypeToJSON(value.diskType),
        'blockSize': value.blockSize,
        'appType': AppTypeToJSON(value.appType),
        'gceRuntime': value.gceRuntime,
        'creator': value.creator,
        'createdDate': value.createdDate,
        'dateAccessed': value.dateAccessed,
    };
}

