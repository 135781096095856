/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * a domain for concepts corresponding to a table in the OMOP schema
 * @export
 */
export const Domain = {
    OBSERVATION: 'OBSERVATION',
    PROCEDURE: 'PROCEDURE',
    DRUG: 'DRUG',
    CONDITION: 'CONDITION',
    MEASUREMENT: 'MEASUREMENT',
    DEVICE: 'DEVICE',
    DEATH: 'DEATH',
    VISIT: 'VISIT',
    SURVEY: 'SURVEY',
    PERSON: 'PERSON',
    PHYSICAL_MEASUREMENT: 'PHYSICAL_MEASUREMENT',
    ALL_EVENTS: 'ALL_EVENTS',
    LAB: 'LAB',
    VITAL: 'VITAL',
    FITBIT: 'FITBIT',
    FITBIT_HEART_RATE_SUMMARY: 'FITBIT_HEART_RATE_SUMMARY',
    FITBIT_HEART_RATE_LEVEL: 'FITBIT_HEART_RATE_LEVEL',
    FITBIT_ACTIVITY: 'FITBIT_ACTIVITY',
    FITBIT_INTRADAY_STEPS: 'FITBIT_INTRADAY_STEPS',
    FITBIT_SLEEP_DAILY_SUMMARY: 'FITBIT_SLEEP_DAILY_SUMMARY',
    FITBIT_SLEEP_LEVEL: 'FITBIT_SLEEP_LEVEL',
    PHYSICAL_MEASUREMENT_CSS: 'PHYSICAL_MEASUREMENT_CSS',
    WHOLE_GENOME_VARIANT: 'WHOLE_GENOME_VARIANT',
    LR_WHOLE_GENOME_VARIANT: 'LR_WHOLE_GENOME_VARIANT',
    STRUCTURAL_VARIANT_DATA: 'STRUCTURAL_VARIANT_DATA',
    SNP_INDEL_VARIANT: 'SNP_INDEL_VARIANT',
    ZIP_CODE_SOCIOECONOMIC: 'ZIP_CODE_SOCIOECONOMIC',
    ARRAY_DATA: 'ARRAY_DATA',
    CONCEPT_SET: 'CONCEPT_SET',
    CONCEPT_QUICK_ADD: 'CONCEPT_QUICK_ADD',
    WEAR_CONSENT: 'WEAR_CONSENT'
} as const;
export type Domain = typeof Domain[keyof typeof Domain];


export function DomainFromJSON(json: any): Domain {
    return DomainFromJSONTyped(json, false);
}

export function DomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): Domain {
    return json as Domain;
}

export function DomainToJSON(value?: Domain | null): any {
    return value as any;
}

