/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Cohort,
  CohortCountQuery,
  CohortCreateInfo,
  CohortUpdateInfo,
  ErrorReport,
  InstanceCountList,
} from '../models';
import {
    CohortFromJSON,
    CohortToJSON,
    CohortCountQueryFromJSON,
    CohortCountQueryToJSON,
    CohortCreateInfoFromJSON,
    CohortCreateInfoToJSON,
    CohortUpdateInfoFromJSON,
    CohortUpdateInfoToJSON,
    ErrorReportFromJSON,
    ErrorReportToJSON,
    InstanceCountListFromJSON,
    InstanceCountListToJSON,
} from '../models';

export interface CreateCohortRequest {
    studyId: string;
    cohortCreateInfo: CohortCreateInfo;
}

export interface DeleteCohortRequest {
    studyId: string;
    cohortId: string;
    cohortRevisionId?: string;
}

export interface GetCohortRequest {
    studyId: string;
    cohortId: string;
    cohortRevisionId?: string;
}

export interface ListCohortsRequest {
    studyId: string;
    offset?: number;
    limit?: number;
}

export interface QueryCohortCountsRequest {
    studyId: string;
    cohortId: string;
    cohortCountQuery: CohortCountQuery;
}

export interface UpdateCohortRequest {
    studyId: string;
    cohortId: string;
    cohortUpdateInfo: CohortUpdateInfo;
    cohortRevisionId?: string;
}

/**
 * 
 */
export class CohortsApi extends runtime.BaseAPI {

    /**
     * Create a new cohort
     */
    async createCohortRaw(requestParameters: CreateCohortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cohort>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling createCohort.');
        }

        if (requestParameters.cohortCreateInfo === null || requestParameters.cohortCreateInfo === undefined) {
            throw new runtime.RequiredError('cohortCreateInfo','Required parameter requestParameters.cohortCreateInfo was null or undefined when calling createCohort.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CohortCreateInfoToJSON(requestParameters.cohortCreateInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortFromJSON(jsonValue));
    }

    /**
     * Create a new cohort
     */
    async createCohort(requestParameters: CreateCohortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cohort> {
        const response = await this.createCohortRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a cohort
     */
    async deleteCohortRaw(requestParameters: DeleteCohortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling deleteCohort.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling deleteCohort.');
        }

        const queryParameters: any = {};

        if (requestParameters.cohortRevisionId !== undefined) {
            queryParameters['cohortRevisionId'] = requestParameters.cohortRevisionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a cohort
     */
    async deleteCohort(requestParameters: DeleteCohortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCohortRaw(requestParameters, initOverrides);
    }

    /**
     * Get an existing cohort
     */
    async getCohortRaw(requestParameters: GetCohortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cohort>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling getCohort.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling getCohort.');
        }

        const queryParameters: any = {};

        if (requestParameters.cohortRevisionId !== undefined) {
            queryParameters['cohortRevisionId'] = requestParameters.cohortRevisionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortFromJSON(jsonValue));
    }

    /**
     * Get an existing cohort
     */
    async getCohort(requestParameters: GetCohortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cohort> {
        const response = await this.getCohortRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all cohorts in a study
     */
    async listCohortsRaw(requestParameters: ListCohortsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Cohort>>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling listCohorts.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CohortFromJSON));
    }

    /**
     * List all cohorts in a study
     */
    async listCohorts(requestParameters: ListCohortsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Cohort>> {
        const response = await this.listCohortsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Run a count query for a cohort
     */
    async queryCohortCountsRaw(requestParameters: QueryCohortCountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstanceCountList>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling queryCohortCounts.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling queryCohortCounts.');
        }

        if (requestParameters.cohortCountQuery === null || requestParameters.cohortCountQuery === undefined) {
            throw new runtime.RequiredError('cohortCountQuery','Required parameter requestParameters.cohortCountQuery was null or undefined when calling queryCohortCounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/counts`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CohortCountQueryToJSON(requestParameters.cohortCountQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceCountListFromJSON(jsonValue));
    }

    /**
     * Run a count query for a cohort
     */
    async queryCohortCounts(requestParameters: QueryCohortCountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstanceCountList> {
        const response = await this.queryCohortCountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing cohort
     */
    async updateCohortRaw(requestParameters: UpdateCohortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cohort>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling updateCohort.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling updateCohort.');
        }

        if (requestParameters.cohortUpdateInfo === null || requestParameters.cohortUpdateInfo === undefined) {
            throw new runtime.RequiredError('cohortUpdateInfo','Required parameter requestParameters.cohortUpdateInfo was null or undefined when calling updateCohort.');
        }

        const queryParameters: any = {};

        if (requestParameters.cohortRevisionId !== undefined) {
            queryParameters['cohortRevisionId'] = requestParameters.cohortRevisionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CohortUpdateInfoToJSON(requestParameters.cohortUpdateInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortFromJSON(jsonValue));
    }

    /**
     * Update an existing cohort
     */
    async updateCohort(requestParameters: UpdateCohortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cohort> {
        const response = await this.updateCohortRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
