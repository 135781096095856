/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InstitutionMembershipRequirement } from './InstitutionMembershipRequirement';
import {
    InstitutionMembershipRequirementFromJSON,
    InstitutionMembershipRequirementFromJSONTyped,
    InstitutionMembershipRequirementToJSON,
} from './InstitutionMembershipRequirement';

/**
 * Institution-specific access tier membership requirement and configs.
 * @export
 * @interface InstitutionTierConfig
 */
export interface InstitutionTierConfig {
    /**
     * The short unique name for the access tier.
     * @type {string}
     * @memberof InstitutionTierConfig
     */
    accessTierShortName: string;
    /**
     * 
     * @type {InstitutionMembershipRequirement}
     * @memberof InstitutionTierConfig
     */
    membershipRequirement: InstitutionMembershipRequirement;
    /**
     * Whether eRA commons is required for members in the institution for this tier.
     * @type {boolean}
     * @memberof InstitutionTierConfig
     */
    eraRequired?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof InstitutionTierConfig
     */
    emailDomains?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InstitutionTierConfig
     */
    emailAddresses?: Array<string>;
}

/**
 * Check if a given object implements the InstitutionTierConfig interface.
 */
export function instanceOfInstitutionTierConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessTierShortName" in value;
    isInstance = isInstance && "membershipRequirement" in value;

    return isInstance;
}

export function InstitutionTierConfigFromJSON(json: any): InstitutionTierConfig {
    return InstitutionTierConfigFromJSONTyped(json, false);
}

export function InstitutionTierConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstitutionTierConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessTierShortName': json['accessTierShortName'],
        'membershipRequirement': InstitutionMembershipRequirementFromJSON(json['membershipRequirement']),
        'eraRequired': !exists(json, 'eraRequired') ? undefined : json['eraRequired'],
        'emailDomains': !exists(json, 'emailDomains') ? undefined : json['emailDomains'],
        'emailAddresses': !exists(json, 'emailAddresses') ? undefined : json['emailAddresses'],
    };
}

export function InstitutionTierConfigToJSON(value?: InstitutionTierConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessTierShortName': value.accessTierShortName,
        'membershipRequirement': InstitutionMembershipRequirementToJSON(value.membershipRequirement),
        'eraRequired': value.eraRequired,
        'emailDomains': value.emailDomains,
        'emailAddresses': value.emailAddresses,
    };
}

